import React from "react";
import "./DashboardNav.css";
import { Icon } from "@iconify/react";
import "./DashboardNav.css";
import BackgroundImage from "../../../components/BackgroundImage/index.js";
import { Link } from "react-router-dom";

const DashboardNav = () => {
  return (
    <>
      <div className="create-grid-container">
        <div className="first-column"></div>
        <div className="second-column">
          <BackgroundImage />

          <div className="dashboard-nav">
            <div className="button-container">
              <Link to="/patient-search">
                <button>
                  <i className="icon-search-patient">
                    <Icon icon="ic:round-search" />
                  </i>
                  Search for Patient
                </button>
              </Link>
              <Link to="/new-patient-form">
                <button>
                  <i className="icon-add-patient">
                    <Icon icon="bi:person-plus" />
                  </i>
                  Add New Patient
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardNav;
