import React from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import BackgroundImage from "../../../components/BackgroundImage";

const DrDashboard = () => {
  return (
    <>
      <div className="create-grid-container">
        <div className="first-column"></div>
        <div className="second-column">
          <BackgroundImage />
          {/* Dashboard Buttons */}
          <div className="admin-dashboard-nav">
            <div className="admin-manage-button">
              <Link to="/pending-paperwork">
                <button className="admin-manage-btn">
                  <i className="icon-search-patient">
                    <Icon icon="mdi:papers-outline" />
                  </i>{" "}
                  Pending Paperwork
                </button>
              </Link>
            </div>
            <div className="admin-button-container">
              <Link to="/patient-search">
                <button className="admin-search-btn">
                  <i className="icon-search-patient">
                    <Icon icon="ic:round-search" />
                  </i>
                  Search for Patient
                </button>
              </Link>
              <Link to="/new-patient-form">
                <button className="admin-add-btn">
                  <i className="icon-add-patient">
                    <Icon icon="bi:person-plus" />
                  </i>
                  Add New Patient
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DrDashboard;
