import bgImage from "../../assets/images/background_logo.webp";
import "./bgImage.css";

const BackgroundImage = () => {
  return (
    <div className="background-logo">
      <img src={bgImage} alt="Cornerstone Logo" className="background-image" />
    </div>
  );
};

export default BackgroundImage;
