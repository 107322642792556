import React from "react";
import "./patientsearch.css";

const PatientSearch = () => {
  return (
    <div className="patient-search-container">
      <h1>Patient Search</h1>
    </div>
  );
};

export default PatientSearch;
