import { Icon } from "@iconify/react";
import { NavLink } from "react-router-dom";
import footerLogo from "../../assets/images/sidebar_logo.webp";

export const AdminSideNav = () => {
  return (
    <div className="admin-sidenav">
      <div className="sidenav-menu">
        <NavLink
          to="/admin-dashboard"
          className={({ isActive }) =>
            isActive ? "sidenav-link active-link" : "sidenav-link"
          }
        >
          <Icon icon="ri:dashboard-line" /> Dashboard
        </NavLink>
        <NavLink
          to="/new-patient-form"
          className={({ isActive }) =>
            isActive ? "sidenav-link active-link" : "sidenav-link"
          }
        >
          <Icon icon="bi:person-plus" /> Add New Patient
        </NavLink>
        <NavLink
          to="/patient-search"
          className={({ isActive }) =>
            isActive ? "sidenav-link active-link" : "sidenav-link"
          }
        >
          <Icon icon="ic:round-search" /> Search For Patient
        </NavLink>
        <NavLink
          to="/admin-user-search"
          className={({ isActive }) =>
            isActive ? "sidenav-link active-link" : "sidenav-link"
          }
        >
          <Icon icon="fluent:settings-cog-multiple-20-filled" /> Manage Users
        </NavLink>
      </div>
      <div className="sidenav-footer">
        <footer>
          <img src={footerLogo} alt="cornerstone" />
        </footer>
      </div>
    </div>
  );
};
export const DrSideNav = () => {
  return (
    <div className="admin-sidenav">
      <div className="sidenav-menu">
        <NavLink
          to="/dr-dashboard"
          className={({ isActive }) =>
            isActive ? "sidenav-link active-link" : "sidenav-link"
          }
        >
          <Icon icon="ri:dashboard-line" /> Dashboard
        </NavLink>
        <NavLink
          to="/new-patient-form"
          className={({ isActive }) =>
            isActive ? "sidenav-link active-link" : "sidenav-link"
          }
        >
          <Icon icon="bi:person-plus" /> Add New Patient
        </NavLink>
        <NavLink
          to="/patient-search"
          className={({ isActive }) =>
            isActive ? "sidenav-link active-link" : "sidenav-link"
          }
        >
          <Icon icon="ic:round-search" /> Search For Patient
        </NavLink>
        <NavLink
          to="/pending-paperwork"
          className={({ isActive }) =>
            isActive ? "sidenav-link active-link" : "sidenav-link"
          }
        >
          <Icon icon="mdi:papers-outline" /> Pending Paperwork
        </NavLink>
      </div>
      <div className="sidenav-footer">
        <img src={footerLogo} alt="cornerstone" />
      </div>
    </div>
  );
};
export const SideNav = () => {
  return (
    <div className="admin-sidenav">
      <div className="sidenav-menu">
        <NavLink
          to="/dashboard"
          className={({ isActive }) =>
            isActive ? "sidenav-link active-link" : "sidenav-link"
          }
        >
          <i className="icon-dashboard">
            <Icon icon="ri:dashboard-line" />
          </i>{" "}
          Dashboard
        </NavLink>
        <NavLink
          to="/new-patient-form"
          className={({ isActive }) =>
            isActive ? "sidenav-link active-link" : "sidenav-link"
          }
        >
          <i className="icon-add-patient">
            <Icon icon="bi:person-plus" />
          </i>{" "}
          Add New Patient
        </NavLink>
        <NavLink
          to="/patient-search"
          className={({ isActive }) =>
            isActive ? "sidenav-link active-link" : "sidenav-link"
          }
        >
          <i className="icon-patient-search">
            <Icon icon="ic:round-search" />
          </i>{" "}
          Search For Patient
        </NavLink>
      </div>
      <div className="sidenav-footer">
        <footer>
          <img src={footerLogo} alt="cornerstone" />
        </footer>
      </div>
    </div>
  );
};
export const DefaultSideNav = () => {
  return (
    <div className="sidenav-container">
      <div className="sidenav">
        <div className="sidenav-menu">
          <NavLink
            to="/dashboard"
            className="sidenav-link"
            activeClassName="active-link"
          >
            <i className="icon-dashboard">
              <Icon icon="ri:dashboard-line" />
            </i>{" "}
            Dashboard
          </NavLink>
          <NavLink
            to="/new-patient-form"
            className="sidenav-link"
            activeClassName="active-link"
          >
            <i className="icon-add-patient">
              <Icon icon="bi:person-plus" />
            </i>{" "}
            Add New Patient
          </NavLink>
          <NavLink
            to="/patient-search"
            className="sidenav-link"
            activeClassName="active-link"
          >
            <i className="icon-patient-search">
              <Icon icon="ic:round-search" />
            </i>{" "}
            Search For Patient
          </NavLink>
        </div>
      </div>
      <div className="sidenav-footer">
        <footer>
          <img src={footerLogo} alt="cornerstone" />
        </footer>
      </div>
    </div>
  );
};
