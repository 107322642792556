import React, { useState } from "react";
import { validateOTP, updatePassword } from "../../utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import "./otp.css";

const OTPValidation = () => {
  // otp reset password states
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email || "");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  
  const navigate = useNavigate();

  const handleOTPValidation = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("New passwords do not match.");
      return;
    }

    try {
      // validate otp with request to "/api/users/check-otp"
      const otpValidationResponse = await validateOTP({ email, otp });

      if (otpValidationResponse.ok) {
        // if OTP is valid, update the password
        const passwordUpdateResponse = await updatePassword({
          email,
          otp,
          newPassword,
        });

        if (passwordUpdateResponse.ok) {
          setErrorMessage(
            "Password successfully updated. You can now log in with your new password."
          );
          navigate("/");
        } else {
          const { message } = await passwordUpdateResponse.json();
          setErrorMessage(message || "Failed to update password.");
        }
      } else {
        const { message } = await otpValidationResponse.json();
        setErrorMessage(message || "Invalid OTP.");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred during the password reset process.");
    }
  };

  return (
    <section className="otp-form-page">
      <div className="otp-box">
        <form className="otp-form" onSubmit={handleOTPValidation}>
          <div className="otp-validation-page">
            <input
              placeholder="Email"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <input
              placeholder="OTP"
              type="number"
              id="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              className="otp-input"
            />

            <input
              placeholder="Password"
              type="text"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="otp-input"
            />

            <input
              placeholder="Confirm New Password"
              type="text"
              id="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
              className="otp-input"
            />

            <div className="otp-button-container">
              <button type="submit" className="reset-btn">
                Reset Password
              </button>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </form>
      </div>
    </section>
  );
};

export default OTPValidation;
