import React, { useState, useEffect } from "react";
import "./adminViewUser.css";
import BackgroundImage from "../../../components/BackgroundImage";
import placeholder from "../../../assets/images/guest.webp";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { getUserProfile, udpateUser } from "../../../utils/api";

const AdminViewUser = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [isLoading, setLoading] = useState(true);

  const displayPhoto = (image) => {
    if (!image) {
      return placeholder;
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `/uploads/${image}`;
    }
  };

  const blockUser = () => {
    const shouldBlock = window.confirm(
      "Are you sure you want to block this user?"
    );

    if (shouldBlock) {
      // Delete User
      udpateUser({ isDeleted: true }, user.id)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          navigate(`/admin-user-search`);
        })
        .catch((err) => console.log(err));
    } else {
      return;
    }
  };

  useEffect(() => {
    const user_id = window.location.href.split("/")[4];

    // Get recent Users on page load
    getUserProfile(user_id)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setUser(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <div className="create-grid-container">
        <div className="first-column"></div>
        <div className="second-column">
          <BackgroundImage />

          {/* Breadcrumb */}
          <div className="manage-users-breadcrumb">
            <Link to="/admin-user-search">Users</Link>
            <Icon icon="ph:greater-than-bold" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="create-grid-container">
      <div className="first-column"></div>
      <div className="second-column">
        <BackgroundImage />

        {/* Breadcrumb */}
        <div className="manage-users-breadcrumb">
          <Link to="/admin-user-search">Users</Link>
          <Icon icon="ph:greater-than-bold" />
          <p>
            {user.first_name} {user.last_name}
          </p>
        </div>

        {/* Profile Card */}
        <div className="user-profile-card">
          <div>
            <img src={displayPhoto(user.image)} alt="placeholder profile" />
          </div>

          {/* Name */}
          <div className="user-card-line">
            <p className="line-heading">Name</p>
            <p className="line-info">
              {user.first_name} {user.last_name}
            </p>
          </div>

          {/* Email */}
          <div className="user-card-line">
            <p className="line-heading">Email</p>
            <p className="line-info">{user.email}</p>
          </div>

          {/* Role */}
          <div className="user-card-line">
            <p className="line-heading">Role</p>
            <p className="line-info">{user.role}</p>
          </div>

          {/* Edit and Block buttons */}
          <div>
            <button
              className="edit-user"
              onClick={() => navigate(`/admin-edit-user/${user.id}`)}
            >
              Edit User
            </button>
          </div>
          <div>
            <button className="block-user" onClick={() => blockUser()}>
              Block User
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminViewUser;
