// ------------- User Fetch Requests -------------
// Signup Page
export const createUser = (userData) => {
  return fetch("/api/users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

// Login Page
export const loginUser = (userData) => {
  return fetch("/api/users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};

// User Profile Pages
export const udpateUser = (userData, user_id) => {
  return fetch(`/api/users/${user_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });
};
export const userRole = (user_id) => {
  return fetch(`/api/users/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const getUserProfile = (user_id) => {
  return fetch(`/api/users/${user_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateTokenImage = (user_id) => {
  return fetch(`/api/users/newImage/${user_id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const addFile = (formData) => {
  return fetch("/api/uploads/create", {
    method: "POST",
    body: formData,
  });
};
// Admin Pages
export const getUsersbyEmail = (searchTerm) => {
  return fetch(`/api/users/email/${searchTerm}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getUsersbyName = (searchTerm) => {
  return fetch(`/api/users/name/${searchTerm}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPendingUsers = () => {
  return fetch(`/api/users/pending`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getRecentUsers = () => {
  return fetch(`/api/users/recent`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
//password

export const forgotPassword = async (payload) => {
  return fetch("/api/users/forgot-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

export const updatePassword = ({ email, otp, newPassword }) => {
  console.log({ email, otp, newPassword });
  return fetch("/api/users/update-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      otp,
      newPassword,
    }),
  });
};
export const validateOTP = async (payload) => {
  return fetch("/api/users/check-otp", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};
