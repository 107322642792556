import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./AdminUserSearch.css";
import {
  getUsersbyEmail,
  getUsersbyName,
  getRecentUsers,
} from "../../../utils/api";
import BackgroundImage from "../../../components/BackgroundImage";
import placeholder from "../../../assets/images/guest.webp";
import { useNavigate } from "react-router-dom";

const AdminUserSearch = () => {
  // Get the current location object
  const location = useLocation();
  // State to manage the menu's open/close status
  const [menuOpen, setMenuOpen] = useState(false);
  // Function to toggle the menu open or closed
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  // Function to get the active tab name based on the current URL path
  const getActiveTabName = () => {
    switch (location.pathname) {
      case "/admin-user-search":
        return "Search for Users";
      case "/admin-approval":
        return "Pending Approval";
      case "/admin-create":
        return "Create New User";
      default:
        return "";
    }
  };

  /** Track Users+Searchbar input */
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchType, setSearchType] = useState("Name");
  const [userInput, setUserInput] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();

    if (userInput.trim() !== "") {
      switch (searchType) {
        case "Name":
          // Search by Name
          getUsersbyName(userInput.trim())
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              setUsers(data);
            })
            .catch((err) => console.log(err));
          break;
        case "Email":
          // Search by Email
          getUsersbyEmail(userInput.trim())
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              setUsers(data);
            })
            .catch((err) => console.log(err));
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    // Get recent Users on page load
    getRecentUsers()
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setUsers(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (isLoading) {
    return (
      <div className="grid-container">
        <div className="first-column"></div>
        <div className="second-column">
          <div className="second-column-item-heading">
            {/* Heading */}
            <h2 className="search-heading">Manage Users</h2>
          </div>
          <div className="second-column-item-links">
            <div className="menu-header">
              {/* Hamburger menu for toggling */}
              <div className="hamburger-menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* Display the active tab name */}
              <div className="active-tab-name">{getActiveTabName()}</div>
            </div>
            {/* Menu items that toggle open/close */}
            <div className={`menu-items ${menuOpen ? "open" : ""}`}>
              <Link
                to="/admin-user-search"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-user-search" ? "active" : ""
                }`}
              >
                Search for Users
              </Link>
              <Link
                to="/admin-approval"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-approval" ? "active" : ""
                }`}
              >
                Pending Approval
              </Link>
              <Link
                to="/admin-create"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-create" ? "active" : ""
                }`}
              >
                Create New User
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Main container with a grid layout */}
      <div className="create-grid-container">
        <div className="first-column"></div>
        <div className="second-column">
          <BackgroundImage />
          <div className="second-column-item-heading">
            {/* Heading */}
            <h2 className="search-heading">Manage Users</h2>
          </div>
          <div className="second-column-item-links">
            <div className="menu-header">
              {/* Hamburger menu for toggling */}
              <div className="hamburger-menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* Display the active tab name */}
              <div className="active-tab-name">{getActiveTabName()}</div>
            </div>
            {/* Menu items that toggle open/close */}
            <div className={`menu-items ${menuOpen ? "open" : ""}`}>
              <Link
                to="/admin-user-search"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-user-search" ? "active" : ""
                }`}
              >
                Search for Users
              </Link>
              <Link
                to="/admin-approval"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-approval" ? "active" : ""
                }`}
              >
                Pending Approval
              </Link>
              <Link
                to="/admin-create"
                className={`admin-search-tabs ${
                  location.pathname === "/admin-create" ? "active" : ""
                }`}
              >
                Create New User
              </Link>
            </div>
          </div>
          <div className="second-column-item-search">
            <div className="search-row">
              <select
                aria-label="name-email"
                className="admin-search-dropdown"
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
              >
                <option value={"Name"}>Name</option>
                <option value={"Email"}>Email</option>
              </select>
              <div className="user-search-input-container">
                <input
                  type="text"
                  placeholder="Search"
                  className="admin-search-input"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                />
                <button
                  className="mobile-search-btn"
                  onClick={(e) => handleSearch(e)}
                >
                  <Icon icon="iconamoon:search-bold" />
                </button>
              </div>

              <button
                className="admin-user-search-btn"
                onClick={(e) => handleSearch(e)}
              >
                Search
                <i className="icon-search-user">
                  <Icon icon="iconamoon:search-bold" />
                </i>
              </button>
            </div>
          </div>
          {/* Cards */}
          <div className="approval-container">
            {users.map((user) => (
              <UserCard user={user} key={user.id} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const UserCard = ({ user }) => {
  const navigate = useNavigate();

  const displayPhoto = (image) => {
    if (!image) {
      return placeholder;
    } else if (image.includes("http") || image.includes("https")) {
      return `${image}`;
    } else {
      return `/uploads/${image}`;
    }
  };

  return (
    <div
      className="admin-card-container-approval user-search-cards"
      onClick={() => navigate(`/admin-view-user/${user.id}`)}
    >
      <div className="profile-info-container">
        <div className="profile-image">
          <img
            src={displayPhoto(user.image)}
            alt="Profile"
            className="user-img-approval"
          />
        </div>
        <div className="profile-info-text-approval">
          {/* User's name */}
          <h3 className="card-heading-approval">
            {user.first_name} {user.last_name}
          </h3>
          <div className="info-item-approval">
            <Icon icon="ic:outline-email" />
            {/* Email  */}
            <p>{user.email}</p>
          </div>
          {/* User's job*/}
          <div className="info-item-approval">
            <Icon icon="ph:briefcase" />
            <p>{user.role}</p>
          </div>
        </div>
      </div>

      <div className="user-search-edit-icons">
        <Icon icon="uil:edit" />
      </div>
    </div>
  );
};

export default AdminUserSearch;
