import React, { useState } from "react";
import { createUser } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import "./Signup.css";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/images/sidebar_logo.webp";
import bgImage from "../../assets/images/background_logo.webp";

const Signup = () => {
  const navigate = useNavigate();

  // Form States
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [user_type, setUserType] = useState("");
  const [emailAlert, setEmailAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    if (password !== confirmPassword) {
      setPasswordMatch(false);
      setErrorMessage("Passwords do not match.");
      return;
    }

    setPasswordMatch(true);
    setErrorMessage("");

    const formData = {
      // Gather Data for query
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      role: user_type,
    };

    // Try to Sign the User up
    try {
      const response = await createUser(formData);

      if (!response.ok) {
        const errorMessage = await response.json();
        const message = errorMessage.errors[0].message;
        // Handle Unique Email Validation Error

        if (message === "email must be unique") {
          console.log("Email Should be Unique");
          document.getElementById("email").focus();
          setEmailAlert(true);
        }

        throw new Error("something went wrong!");
      }

      const responseData = await response.json();

      const { token, user_type, id } = responseData;

      if (!token || !user_type) {
        setErrorMessage("Unexpected response from the server.");
        console.log("Missing token or user_type in response", responseData);
        return;
      }

      // If successfull, show success page
      navigate("/signup-success");
    } catch (err) {
      console.error(err);
      setErrorMessage("An error occurred during signup.");
    }
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (confirmPassword) {
      setPasswordMatch(e.target.value === confirmPassword);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordMatch(e.target.value === password);
  };
  return (
    <>
      <div className="auth-background-logo">
        <img
          src={bgImage}
          alt="Cornerstone Logo"
          className="background-image"
        />
      </div>
      <div className="center-container">
        <div className="signup-container">
          <div className="signup-box">
            <h2 className="signup-title">Create an account</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="signup-form-group">
                <input
                  type="text"
                  id="first"
                  name="first"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  placeholder="First"
                  className="signup-input"
                />
              </div>
              <div className="signup-form-group">
                <input
                  type="text"
                  id="last"
                  name="last"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  placeholder="Last"
                  className="signup-input"
                />
              </div>
              {emailAlert && (
                <span>
                  <p className="email-alert">
                    *** Email address already in use. ***
                  </p>
                </span>
              )}
              <div className="signup-form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email"
                  className="signup-input"
                />
              </div>
              <div className="signup-form-group">
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                  placeholder="Password"
                  className="signup-input"
                />
              </div>
              <div className="signup-form-group">
                <input
                  type="password"
                  id="confirm-password"
                  name="confirm-password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                  placeholder="Confirm Password"
                  className="signup-input"
                />
              </div>
              {!passwordMatch && (
                <p className="password-mismatch">Passwords do not match.</p>
              )}
              <div className="signup-form-group">
                <select
                  className="signup-dropdown signup-input"
                  value={user_type}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  <option value="">Role</option>
                  <option value="Doctor">Doctor</option>
                  <option value="Anesthesiologist">Anesthesiologist</option>
                  <option value="RN">RN (Registered Nurse)</option>
                  <option value="LVN">LVN (Licensed Vocational Nurse)</option>
                  <option value="Tech">Tech</option>
                </select>
              </div>
              <button type="submit" className="signup-btn">
                Sign up
              </button>
            </form>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="signup-links">
              <p className="account-text">
                Already have an account?
                <span>
                  <Link to="/" className="signup-link">
                    Sign in!
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer className="signup-footer">
        <img src={footerLogo} alt="cornerstone" />
      </footer>
    </>
  );
};

export default Signup;
