// Route the user to their "dashboard" based on user_type
export const reroute = (user_type) => {
  switch (user_type) {
    case "Anesthesiologist":
      window.location.assign("/dashboard");
      return;
    case "RN":
      window.location.assign("/dashboard");
      return;
    case "LVN":
      window.location.assign("/dashboard");
      return;
    case "Tech":
      window.location.assign("/dashboard");
      return;
    case "Doctor":
      window.location.assign("/dr-dashboard");
      return;
    case "Admin":
      window.location.assign("/admin-dashboard");
      return;
    default:
      return null;
  }
};
