import React from "react";
import "./newpatientform.css";
const NewPatientForm = () => {
  return (
    <div className="patient-container">
    
        <h1>New Patient Form</h1>
   
    </div>
  );
};

export default NewPatientForm;
