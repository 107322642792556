import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Auth from "./utils/auth";

import Login from "./components/Login";
import Signup from "./components/Signup";
import Nav from "./components/Nav";
import SignupSuccess from "./pages/User/SignupSuccess";
// User Pages
import DashboardNav from "./pages/User/DashboardNav";
import Profile from "./pages/User/Profile";
import NewPatientForm from "./pages/User/NewPatientForm";
import PatientSearch from "./pages/User/PatientSearch";
// Admin Pages
import AdminSideNav from "./pages/Admin/AdminSideNav";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminUserSearch from "./pages/Admin/AdminUserSearch";
import AdminApproval from "./pages/Admin/AdminApproval";
import AdminCreate from "./pages/Admin/AdminCreate";
//Doctor Pages
import DrDashboard from "./pages/Doctor/Dashboard";
import DrSideNav from "./pages/Doctor/SideNav";
import PendingPaperwork from "./pages/Doctor/PendingPaperWork";
// Admin Pages
import AdminEditUser from "./pages/Admin/AdminEditUser";
import AdminViewUser from "./pages/Admin/AdminViewUser";
//Forgot Password Pages
import ForgotPassword from "./components/ForgotPassword";
import OTPValidation from "./components/OTP";

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

const App = () => {
  let user;

  if (Auth.loggedIn() !== false) {
    user = Auth.getUser();
    // console.log(user);
  }

  // Allow users to access routes IF logged in
  function checkUser() {
    if (user !== undefined) {
      // Conditionally routes based on the user_type
      switch (user.role) {
        case "Doctor":
          console.log("doctor");
          return doctorRoutes;
        case "Admin":
          console.log("admin");
          return adminRoutes;
        default:
          console.log("null");
          return null;
      }
    }
  }

  const adminRoutes = (
    <>
      {/* Admin */}
      <Route path="/admin-nav" element={<AdminSideNav />} />
      <Route path="/admin-dashboard" element={<AdminDashboard />} />
      <Route path="/admin-user-search" element={<AdminUserSearch />} />
      <Route path="/admin-approval" element={<AdminApproval />} />
      <Route path="/admin-create" element={<AdminCreate />} />
      <Route path="/admin-edit-user/:id" element={<AdminEditUser />} />
      <Route path="/admin-view-user/:id" element={<AdminViewUser />} />
    </>
  );

  const doctorRoutes = (
    <>
      {/* Doctor */}
      <Route path="/dr-dashboard" element={<DrDashboard />} />
      <Route path="/dr-sidenav" element={<DrSideNav />} />
      <Route path="/pending-paperwork" element={<PendingPaperwork />} />
    </>
  );

  const isLoggedIn = () => {
    if (user !== undefined) {
      return (
        <>
          {/* Logged In Routes (For all User types) */}
          <Route path="/dashboard" element={<DashboardNav />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/new-patient-form" element={<NewPatientForm />} />
          <Route path="/patient-search" element={<PatientSearch />} />
        </>
      );
    }
  };

  return (
    <>
      <Nav />
      <Routes>
        {/* All User */}
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup-success" element={<SignupSuccess />} />

        {/* Forgot Password */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/otp" element={<OTPValidation />} />

        {/* Additional Routes */}
        {isLoggedIn()}
        {checkUser()}

        <Route path="*" element={<Login />} />
      </Routes>
    </>
  );
};

export default AppWrapper;
