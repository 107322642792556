import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import footerLogo from "../../../assets/images/sidebar_logo.webp";

const DrSideNav = () => {
  return (
    <div className="admin-sidenav">
      <div className="sidenav-menu">
        <Link to="/admin-dashboard" className="sidenav-link">
          <Icon icon="ri:dashboard-line" /> Dashboard
        </Link>
        <Link to="/add-patient" className="sidenav-link">
          <Icon icon="bi:person-plus" /> Add New Patient
        </Link>
        <Link to="/search-patient" className="sidenav-link">
          <Icon icon="ic:round-search" /> Search For Patient
        </Link>
        <Link to="/pending-paperwork" className="sidenav-link">
          <Icon icon="mdi:papers-outline" /> Pending Paperwork
        </Link>
      </div>
      <div className="sidenav-footer">
        <img src={footerLogo} alt="cornerstone" />
      </div>
    </div>
  );
};

export default DrSideNav;
